import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface editCollectionModalInterface {
    collectionId?: number
}

const initialState: editCollectionModalInterface = {}

const editCollectionModal = createSlice({
    name: 'editCollectionNameModal',
    initialState,
    reducers: {
        setEditCollectionModal: (state, action: PayloadAction<{
            collectionId?: number,
            showPopup: boolean
        }>) => {
            if (action.payload.showPopup) {
                state.collectionId = action.payload.collectionId
            } else {
                delete state.collectionId
            }
        }
    },
})


export const {setEditCollectionModal} = editCollectionModal.actions
export const editCollectionModalReducer = editCollectionModal.reducer
