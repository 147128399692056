export const ApiEnum = {
  USER: process.env.REACT_APP_ADMIN_URL + "/api/dealer/user",
  TRANSLATIONS: process.env.REACT_APP_ADMIN_URL + "/api/dealer/translations",
  PRODUCT_RANGE: process.env.REACT_APP_ADMIN_URL + "/api/dealer/product-range",
  PRODUCT_DETAILS: process.env.REACT_APP_ADMIN_URL + "/api/dealer/products",
  COLLECTIONS: process.env.REACT_APP_ADMIN_URL + "/api/dealer/collections",
  AUTH_SETTINGS: process.env.REACT_APP_ADMIN_URL + "/api/dealer/authentication-settings",
  PRICE_BOOKS: process.env.REACT_APP_ADMIN_URL + "/api/dealer/price-book",
};

