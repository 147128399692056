import Carousel from "react-elastic-carousel";
import {breakPointsOlderModelsSlider} from "./BreakPointsCarousel";
import {useEffect, useRef, useState} from "react";
import {ProductOlderModelsItem} from "./ProductOlderModelsItem";
import {ProductDetailsDataInterface} from "../../interfaces/ProductDetailsDataInterface";
import {LanguageInterface} from "../../interfaces/LanguageInterface";

interface Props {
    data: ProductDetailsDataInterface,
    activeLanguage: LanguageInterface,
    title: string,
}

export const ProductOlderModels = (props: Props) => {
    const { activeLanguage, title} = props;
    const {olderModelAssociations} = props.data;
    let carouselProductSliderLeftRef: any = useRef();

    const [maxItemsToShow, setMaxItemsToShow] = useState<number>(6);

    const calculateItemsToShow = (width: number) => {
        let itemsToShow = 1;
        for (let i = 0; i < breakPointsOlderModelsSlider.length; i++) {
            if (width >= breakPointsOlderModelsSlider[i].width) {
                itemsToShow = breakPointsOlderModelsSlider[i].itemsToShow;
            }
        }
        return itemsToShow;
    };

    useEffect(() => {
        const updateItemsToShow = () => {
            setMaxItemsToShow(calculateItemsToShow(window.innerWidth));
        };

        updateItemsToShow();
        window.addEventListener("resize", updateItemsToShow);

        return () => {
            window.removeEventListener("resize", updateItemsToShow);
        };
    }, []);

    const filledItems = [...olderModelAssociations];
    if (filledItems.length < maxItemsToShow) {
        const emptyItemsToAdd = maxItemsToShow - filledItems.length;
        for (let i = 0; i < emptyItemsToAdd; i++) {
            filledItems.push({ id: 0, label: '', image: '' });
        }
    }

    return (
        <div className="c-older-models">
            <div className="c-older-models__header">
                <h3>{title}</h3>
                <div className="c-older-models__header__nav">
                    <div
                        className="c-older-models__header__nav__button c-older-models__header__nav__button--left"
                        onClick={() => carouselProductSliderLeftRef.slidePrev()}
                    >
                        <img src="/images/arrow-black.svg" alt="arrow-left"/>
                    </div>
                    <div
                        className="c-older-models__header__nav__button c-older-models__header__nav__button--right"
                        onClick={() => carouselProductSliderLeftRef.slideNext()}
                    >
                        <img src="/images/arrow-black.svg" alt="arrow-right"/>
                    </div>
                </div>
            </div>
            <div className="c-older-models__slider">
                <Carousel
                    ref={ref => (carouselProductSliderLeftRef = ref)}
                    isRTL={false}
                    showArrows={false}
                    pagination={false}
                    breakPoints={breakPointsOlderModelsSlider}
                >
                    {filledItems.map((item, index) => (
                        item.label || item.image ? (
                            <ProductOlderModelsItem key={index} title={item.label} image={item.image} id={item.id} activeLanguage={activeLanguage}/>
                        ) : (
                            <div key={index} className="c-older-models__empty-item"/> // Render lege item
                        )
                    ))}
                </Carousel>
            </div>
        </div>
    );
};
