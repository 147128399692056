import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface addCollectionProductsModalInterface {
    collectionId?: number
}

const initialState: addCollectionProductsModalInterface = {}

const addCollectionProductsModal = createSlice({
    name: 'addCollectionProductsModal',
    initialState,
    reducers: {
        setAddCollectionProductsModal: (state, action: PayloadAction<{
            collectionId?: number,
            showPopup: boolean
        }>) => {
            if (action.payload.showPopup) {
                state.collectionId = action.payload.collectionId
            } else {
                delete state.collectionId
            }
        }
    },
})


export const {setAddCollectionProductsModal} = addCollectionProductsModal.actions
export const addCollectionProductsModalReducer = addCollectionProductsModal.reducer
