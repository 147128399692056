import {useGetPriceBookQuery} from "./PricebookSlice";
import {Searchbar} from "../../common/components/general/Searchbar";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../app/rootReducer";
import {StringTranslation} from "../../common/utils/StringTranslation";
import {DownloadItem} from "../../common/components/general/DownloadItem";
import {PriceBookInterface} from "../../common/interfaces/PriceBookInterface";
import {DownloadItemSkeleton} from "../../common/components/general/DownloadItemSkeleton";

const PriceBookPageContainer = () => {

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {data: downloads} = useGetPriceBookQuery()
    const {data: translations, isLoading: isTranslationsLoading} = useGetTranslationsQuery();

    const translationsLoaded = !isTranslationsLoading && !!activeLanguage;

    const downloadsAmount = downloads?.length || 0

    let downloadTitle = StringTranslation('downloads', translations, activeLanguage)
    if (downloadsAmount === 1) {
        downloadTitle = StringTranslation('download', translations, activeLanguage)
    }

    return (
        <>
            <div className="p-pricebook">
                <div className="p-pricebook__header">
                    <div className="c-breadcrumbs">

                        {translationsLoaded ?
                            <>
                                <span>{StringTranslation('pricebook', translations, activeLanguage)}</span>
                                <div className="c-breadcrumbs__arrow"><img src="/images/arrow-black.svg" alt="arrow"/>
                                </div>
                                <span>{StringTranslation('downloads', translations, activeLanguage)}</span>
                            </>
                            :
                            <>
                                <span className="skeleton skeleton-name"></span>
                                <div className="c-breadcrumbs__arrow"><img src="/images/arrow-black.svg" alt="arrow"/>
                                </div>
                                <span className="skeleton skeleton-name"></span>
                            </>
                        }


                    </div>
                    <Searchbar activeLanguage={activeLanguage}/>
                </div>

                <div className="p-pricebook__content">

                    <div className="p-pricebook__content__header">
                        {translationsLoaded ?
                            <h2>{downloadsAmount} {downloadTitle}</h2>
                            :
                            // eslint-disable-next-line jsx-a11y/heading-has-content
                            <h2 className="skeleton skeleton-title-large"/>
                        }
                    </div>

                    {downloads && downloads.length > 0 ?
                        <div className="p-pricebook__content__downloads">
                            {downloads.map((item: PriceBookInterface, key: number) => (
                                <DownloadItem key={key} data={item} activeLanguage={activeLanguage}/>
                            ))}
                        </div>
                        :
                        <div className="p-pricebook__content__downloads">
                            {Array(5).fill(0).map((_, index) => (
                                <DownloadItemSkeleton key={index}/>
                            ))}
                        </div>
                    }
                </div>

            </div>
        </>
    )

}

export default PriceBookPageContainer;

