import {RouteEnum} from "../../enums/RouteEnum";
import { useHistory } from 'react-router-dom';
import {LanguageInterface} from "../../interfaces/LanguageInterface";
import {StringTranslation} from "../../utils/StringTranslation";
import {useGetTranslationsQuery} from "../../../app/rootReducer";
import {useEffect, useState} from "react";

interface Props {
    activeLanguage: LanguageInterface | null;
}
export const Searchbar = (props: Props) => {
    let history = useHistory();
    const { activeLanguage } = props;
    const { data: translations, isLoading: isTranslationsLoading } = useGetTranslationsQuery();

    const [placeholderText, setPlaceholderText] = useState('');

    useEffect(() => {
        if (!isTranslationsLoading && activeLanguage) {
            setPlaceholderText(
                StringTranslation('search-for-machines-accessories-or-parts', translations, activeLanguage)
            );
        } else {
            setPlaceholderText('Loading...');
        }
    }, [isTranslationsLoading, translations, activeLanguage]);

    return (
        <div className="c-searchbar">
            <form className="c-searchbar__form">
                <input
                    type="search"
                    onClick={() => {
                        history.push(RouteEnum.SEARCH);
                    }}
                    placeholder={placeholderText}
                />
                <div><img src="/images/search.svg" alt="search" /></div>
            </form>
        </div>
    );
};