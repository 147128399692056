import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import type {RootState, AppDispatch} from './store'
import {useLocation} from "react-router-dom";
import axios from "axios";
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppLocation = () => useLocation()


export const useAppAxios = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_URL,
    headers: {
        "content-type": "application/json"
    },
    responseType: "json"
});
