import {
    mapResults,
    setSearchingVariables,
    useLazySearchProductsQuery
} from "./SearchSlice";
import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {MoonLoader} from "react-spinners";
import {ResultCategory} from "../../common/components/search/ResultCategory";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../app/rootReducer";
import {StringTranslation} from "../../common/utils/StringTranslation";

const SearchPageContainer = () => {
    const dispatch = useAppDispatch();
    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {data: translations} = useGetTranslationsQuery()

    const [trigger] = useLazySearchProductsQuery()

    const {
        machinery,
        accessories,
        archived,
        resultInputValue,
        resultCount,
        isLoading
    } = useAppSelector(state => state.search);

    const [typingTimeout, setTypingTimeout] = useState<number | ReturnType<typeof setTimeout>>(0);

    const searchHandler = (value: string) => {

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        if (value && value !== '') {

            setTypingTimeout(setTimeout(function () {

                dispatch(setSearchingVariables({isLoading: true, clearResults: true, resultValue: value}))
                trigger(value).then((result) => {
                    const {data} = result
                    if (data) {
                        dispatch(mapResults(data))
                        dispatch(setSearchingVariables({isLoading: false, resultCount: data.length}))
                    } else {
                        dispatch(setSearchingVariables({isLoading: false, resultCount: 0}))
                    }
                })

            }, 1000))

        }
    }

    return (
        <div className="p-search">
            <div className="p-search__header">
                <div className="c-searchbar">
                    <form className="c-searchbar__form" onSubmit={(e => e.preventDefault())}>
                        <input type="search" autoFocus onChange={(e) => {
                            searchHandler(e.target.value)
                        }} placeholder={StringTranslation('search-for-machines-accessories-or-parts', translations, activeLanguage)}/>
                        <div><img src="/images/search.svg" alt="search"/></div>
                    </form>
                </div>
            </div>


            {!isLoading ? (
                <div className="p-search__content">

                    <div className="p-search__content__header">
                        <h2>{resultCount} {StringTranslation('results-for', translations, activeLanguage).toLowerCase()} '<u>{resultInputValue}</u>'</h2>
                    </div>

                    <div className="p-search__content__results-container">

                        <ResultCategory products={machinery} category={StringTranslation('machines', translations, activeLanguage)} resultCount={machinery.length}/>
                        <ResultCategory products={accessories} category={StringTranslation('accessories', translations, activeLanguage)} resultCount={accessories.length}/>
                        <ResultCategory products={archived} category={StringTranslation('old-models', translations, activeLanguage)} resultCount={archived.length}/>

                    </div>

                </div>
            ) : (
                <div className={"c-loader"}>
                    <MoonLoader color={"#E52713FF"}/>
                </div>
            )}

        </div>
    );

};

export default SearchPageContainer;
