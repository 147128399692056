export const CollectionItemSkeleton = () => {

    return (
        <div className="c-collection">
            <div className="c-collection__header">

                <div className="c-collection__name">
                    <div className="c-collection__name__icon"></div>
                    <span className="skeleton skeleton-name"></span>
                </div>

                <div className="c-collection__buttons">
                    <div className="c-collection__buttons__edit">
                        <button className="c-collection__button c-collection__button--edit">
                            <img src="/images/pencil.svg" alt="delete"/>
                            <span className="skeleton skeleton-name-small"></span>
                        </button>
                        <button className="c-collection__button c-collection__button--delete">
                            <img src="/images/cross.svg" alt="delete"/>
                            <span className="skeleton skeleton-name-small"></span>
                        </button>
                    </div>
                    <div className="c-collection__button c-collection__button--expand">
                        <div>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}




