import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Video from "yet-another-react-lightbox/dist/plugins/video";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {ProductGalleryModalSliceInterface, setActive} from "./productGalleryModalSlice";

export const ProductGalleryModal = () => {

    const {
        lightboxSlices,
        thumbnailLightBoxSettings,
        isActive
    } = useAppSelector(state => state.productGalleryModal as ProductGalleryModalSliceInterface)

    const dispatch = useAppDispatch();
    const lightboxThumbnailsSetting: any = thumbnailLightBoxSettings;
    const videoPlugin: any = Video;

    // Return null if modal is not active
    if (!isActive) return null;

    return (
        <div className="p-product-details__lightbox">

                <Lightbox
                    open={() => dispatch(setActive(true))}
                    close={() => dispatch(setActive(false))}
                    plugins={[Thumbnails, videoPlugin]}
                    thumbnails={lightboxThumbnailsSetting}
                    slides={lightboxSlices}
                    render={{
                        slide: ({slide, rect}: any) => {
                            return (
                                slide.type === "video-custom" ? (
                                    <iframe
                                        title={slide.src + "/" + slide.width + "/" + slide.height}
                                        width={Math.min(
                                            slide.width,
                                            rect.width,
                                            (slide.width * rect.height) / slide.height
                                        )}
                                        height={Math.min(
                                            slide.height,
                                            rect.height,
                                            (slide.height * rect.width) / slide.width
                                        )}
                                        src={slide.src}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    />
                                ) : undefined)
                        },
                        thumbnail: ({slide}: any) =>
                            slide.type === "video-custom" ? (
                                <img src={slide.poster} style={{width: "auto", height: "100%"}} alt=""/>
                            ) : undefined,
                    }}
                />

        </div>
    )

}
