import {ProductInterface} from "../interfaces/ProductInterface";

export const collectionProductMapper = (products: ProductInterface[], numberOfProducts: number) => {

    let productCollection: ProductInterface[][] = []

    let counter = 0
    let paginationCounter = 0

    products.forEach((product: ProductInterface) => {

        if (!productCollection[paginationCounter]) {
            productCollection[paginationCounter] = [];
        }

        productCollection[paginationCounter].push(product)

        counter++
        if (counter === numberOfProducts){
            paginationCounter++
            counter = 0
        }
    })


    return productCollection
}
