export const RouteEnum = {
    DASHBOARD: '/dashboard',
    PRODUCT_RANGE: '/product-range',
    COLLECTIONS: '/collections',
    FAVOURITES: '/favourites',
    SPARE_PARTS: '/spare-parts',
    ACCOUNT: '/account',
    PRICEBOOK: '/pricebook',
    SEARCH: '/search'
}
