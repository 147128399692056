import {useGetUserInfoQuery} from "../../../app/rootReducer";

interface Props {
    text: string,
    onClick?: () => void
}

export const Tooltip = (props: Props) => {

    const {data} = useGetUserInfoQuery()
    const {languages} = data || {languages: []}

    return (
        <div onClick={props.onClick} className='c-tooltip' style={{width: languages.length <= 1 ? '100%' : '5rem'}}>
            <img src="/images/info.svg" alt="tooltip"/>
            <span className="tooltiptext">{props.text}</span>
        </div>
    )

}
