import {Searchbar} from "../../common/components/general/Searchbar";
import {CollectionItem} from "../../common/components/collections/CollectionItem";
import {mapCollection, useGetCollectionsQuery} from "./CollectionsSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect} from "react";
import {CollectionInterface} from "../../common/interfaces/CollectionInterface";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../app/rootReducer";
import {StringTranslation} from "../../common/utils/StringTranslation";
import {CollectionItemSkeleton} from "../../common/components/collections/CollectionItemSkeleton";

const CollectionsPageContainer = () => {

    const dispatch = useAppDispatch();
    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {data: translations, isLoading: isLoadingTranslations} = useGetTranslationsQuery()
    const {data, isLoading: isLoadingCollections} = useGetCollectionsQuery();

    const translationsLoaded = !isLoadingTranslations && !!activeLanguage;

    useEffect(() => {
        if (data) {
            dispatch(mapCollection(data))
        }
    }, [data, dispatch])

    const {collections} = useAppSelector(state => state.collections);

    return (
        <>
            <div className="p-collections">
                <div className="p-collections__header">
                    <div className="c-breadcrumbs">
                        {translationsLoaded ?
                            <>
                                <span>{StringTranslation('collections', translations, activeLanguage)}</span>
                                <div className="c-breadcrumbs__arrow"><img src="/images/arrow-black.svg" alt="arrow"/>
                                </div>
                                <span>{StringTranslation('overview', translations, activeLanguage)}</span>
                            </>
                            :
                            <>
                                <span className="skeleton skeleton-name"></span>
                                <div className="c-breadcrumbs__arrow"><img src="/images/arrow-black.svg" alt="arrow"/>
                                </div>
                                <span className="skeleton skeleton-name"></span>
                            </>
                        }
                    </div>
                    <Searchbar activeLanguage={activeLanguage}/>
                </div>

                <div className="p-collections__content">

                    <div className="p-collections__content__header">
                        {translationsLoaded ?
                            <h2>{collections.length} {StringTranslation('collections', translations, activeLanguage).toLowerCase()}</h2>
                            :
                            // eslint-disable-next-line jsx-a11y/heading-has-content
                            <h2 className="skeleton skeleton-title-large"/>
                        }
                    </div>

                    {!isLoadingCollections ?
                        <>
                        {collections.length > 0 &&
                            <div className="p-collections__content__collections">
                                {collections.map((collection: CollectionInterface, collectionIndex: number) => (
                                    <CollectionItem key={collectionIndex} collection={collection}/>
                                ))}
                            </div>
                        }
                        </>
                        :
                        <CollectionItemSkeleton/>
                    }
                </div>
            </div>
        </>
    )
}
export default CollectionsPageContainer;
