import {useEffect, useState} from 'react';
import {useGetUserInfoQuery} from "../../../app/rootReducer";

export const MenuAccountInfo = () => {
    const [isDataLoading, setIsDataLoading] = useState(true);
    const {data} = useGetUserInfoQuery();
    const {firstName, insertion, lastName, dealer} = data || {
        firstName: '',
        insertion: '',
        lastName: ''
    };
    const dealerName = dealer?.name;

    useEffect(() => {
        if (!!firstName && !!lastName && !!dealerName) {
            setIsDataLoading(false);
        }
    }, [firstName, lastName, dealerName]);

    return (
        <div className="l-app__menu__account__info">
            {isDataLoading ? (
                <>
                    <span className="skeleton skeleton-name"></span>
                    <span className="skeleton skeleton-company"></span>
                </>
            ) : (
                <>
                    {firstName && lastName && (
                        <span className="l-app__menu__account__name">{firstName} {insertion} {lastName}</span>
                    )}
                    {dealerName && <span className="l-app__menu__account__company">{dealerName}</span>}
                </>
            )}
        </div>
    );
};
