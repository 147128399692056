import {RenderArrowProps} from "react-elastic-carousel";

export const ProductCarouselNavigationItem = ({ type, onClick }: RenderArrowProps ) => {
    const classSelect = type === "PREV"
        ? "c-product-carousel__navigation__item--left"
        : "c-product-carousel__navigation__item--right";

    return (
        <div
            onClick={onClick}
            className={`c-product-carousel__navigation__item ${classSelect}`}>
            <img src="/images/arrow-black.svg" alt="arrow-down"/>
        </div>
    )

}