import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface addCollectionModalInterface {
    selectedProductIds?: [],
    collectionId?: number
    collectionOptions?: object[],
}

const initialState: addCollectionModalInterface = {}

const addCollectionModal = createSlice({
    name: 'editCollectionNameModal',
    initialState,
    reducers: {
        setAddCollectionModal: (state, action: PayloadAction<{
            selectedProductIds?: [],
            showPopup: boolean
        }>) => {
            if (action.payload.showPopup) {
                state.selectedProductIds = action.payload.selectedProductIds
            } else {
                delete state.selectedProductIds
            }
        },
        mapCollection: (state, action: PayloadAction<any>) => {
            let options: object[] = []
            action.payload.forEach((collection: {name: string, id: number}) => {
                options = [
                    ...options,
                    {
                        label: collection.name,
                        value: collection.id
                    }
                ]
            })
            state.collectionOptions = options
        },
        setSelectedCollection: (state, action: PayloadAction<number>) => {
            state.collectionId = action.payload
        }
    },
})


export const {setAddCollectionModal, mapCollection, setSelectedCollection} = addCollectionModal.actions
export const addCollectionModalReducer = addCollectionModal.reducer
