import {useAppDispatch} from "./hooks";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import {useContext, useEffect} from "react";
import {MoonLoader} from "react-spinners";
import {setAuthToken} from "./rootReducer";
import App from "./App";

const AuthWrapper = () => {

    const dispatch = useAppDispatch();

    const {token, loginInProgress, login} = useContext<IAuthContext>(AuthContext)

    dispatch(setAuthToken(token))

    useEffect(() => {
        if (!token && !loginInProgress) {
            login()
        }
    }, [login, loginInProgress, token]);


    if (!!token && !loginInProgress) {
        return <App/>
    } else return (
        <main className="l-app__content" style={{height: '100vh', alignItems: "center"}}>
            <div className={'c-loader'}>
                <MoonLoader color={'#E52713FF'}/>
            </div>
        </main>
    )
}

export default AuthWrapper;
