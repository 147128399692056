interface Props {
  color: string;
}

export const SvgReset = (props: Props) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 13.9998C3.76389 13.8054 2.51736 13.1491 1.51042 12.0311C0.503472 10.913 0 9.58317 0 8.0415C0 7.19428 0.159722 6.40609 0.479167 5.67692C0.798611 4.94775 1.23611 4.31234 1.79167 3.77067L2.85417 4.83317C2.4375 5.23595 2.10764 5.71512 1.86458 6.27067C1.62153 6.82623 1.5 7.4165 1.5 8.0415C1.5 9.1665 1.85764 10.1387 2.57292 10.9582C3.28819 11.7776 4.18056 12.2846 5.25 12.479V13.9998ZM6.75 13.9998V12.479C7.81944 12.2984 8.71181 11.795 9.42708 10.9686C10.1424 10.1422 10.5 9.1665 10.5 8.0415C10.5 6.7915 10.0625 5.729 9.1875 4.854C8.3125 3.979 7.25 3.5415 6 3.5415H5.85417L6.8125 4.49984L5.75 5.5415L3 2.7915L5.75 0.0415039L6.8125 1.104L5.875 2.0415H6C7.66667 2.0415 9.08333 2.62484 10.25 3.7915C11.4167 4.95817 12 6.37484 12 8.0415C12 9.58317 11.4965 10.9165 10.4896 12.0415C9.48264 13.1665 8.23611 13.8193 6.75 13.9998Z"
        fill={props.color}
      />
    </svg>
  );
};
