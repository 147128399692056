import {ProductInterface} from "../../interfaces/ProductInterface";
import {Translate} from "../../utils/Translate";
import {selectProduct} from "../../../pages/productRangePage/ProductRangeSlice";
import {useAppDispatch} from "../../../app/hooks";
import {Link} from "react-router-dom";
import {RouteEnum} from "../../enums/RouteEnum";
import {useGetUserInfoQuery} from "../../../app/rootReducer";
import {currencyFormatter} from "../../utils/currencyFormatter";

interface Props {
    product: ProductInterface;
}

export const ProductSelectedItem = (props: Props) => {

    const dispatch = useAppDispatch()

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}

    const {product} = props

    return (
        <div className="c-products-selected__item">
            <div className="c-products-selected__item__left">
                <div className="cross" onClick={() => dispatch(selectProduct(product))}>
                    <img src="/images/cross.svg" alt="cross"/>
                </div>
                {/*<div className="favourites">*/}
                {/*    <img src="/images/favourites.svg" alt="favourites"/>*/}
                {/*</div>*/}
                <Link to={RouteEnum.PRODUCT_RANGE + `/${product.id}`}>
                    {!!activeLanguage &&
                    <span>{Translate(product?.label, activeLanguage)}</span>
                    }
                </Link>
            </div>
            {!!product.identifier &&
                <div className="c-products-selected__item__center">
                    <span>{product.identifier}</span>
                </div>
            }
            {(product.prices && product.prices.length > 0) &&
                <div className="c-products-selected__item__right">
                    {!!activeLanguage &&
                    <span>{currencyFormatter(product.prices, activeLanguage)}</span>
                    }
                </div>
            }
        </div>
    )
}




