import {SvgDownload} from "../svg/SvgDownload";

export const DownloadItemSkeleton = () => {
    return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className='c-download-item'>
            <div className="c-download-item__left">
                <span className="skeleton skeleton-text"></span>
            </div>
            <span className="c-download-item__middle"><span className="skeleton skeleton-name-super-small"></span></span>
            <span className="c-download-item__right">
                <div>
                    <div className="c-download-item__download-icon">
                      <SvgDownload color={'#151112'}/>
                    </div>
                    <span className="skeleton skeleton-name-small"></span>
                </div>
          </span>
        </a>
    )
}
