export const currencyFormatter = (data: {
  locale: string,
  amount: number,
  currency: string
}[], activeLanguage: any) => {

  let price = data.filter(price => price.locale === activeLanguage.localeCode)[0]

  let locale = price?.locale.replace('_', '-')
  let formattedPrice = ""
  if (price) {
    formattedPrice = Intl.NumberFormat(locale, {
      style: 'currency',
      currency: price.currency,
    }).format(price?.amount)
  } else {
    formattedPrice = ''
  }

  return formattedPrice
}

