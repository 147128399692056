import {Translate} from "../../utils/Translate";
import {StringTranslation} from "../../utils/StringTranslation";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../../app/rootReducer";

interface Props {
    activeLanguage: any,
    id: number,
    title: string,
    image: string,
}

export const ProductOlderModelsItem = (props: Props) => {
    const {data: translations} = useGetTranslationsQuery();
    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}

    return (
        <div className="c-older-models__item">
            <div className="c-older-models__item__top">
                <div className="carousel-product-image">
                    <img
                        src={props.image}
                        alt="thumbnail" />
                </div>
            </div>
            <div className="c-older-models__item__bottom">
                <div className="footer-top">
                    <span>{Translate(props.title, props.activeLanguage)}</span>
                </div>
                <div className="button-container">
                    <div className="c-btn c-btn--primary btn-view-product"><a
                        href={`/product-range/${props.id}`}>{StringTranslation('view-product', translations, activeLanguage)}</a></div>
                </div>
            </div>
        </div>
    )
}