import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setDeleteCollectionProductModal} from "./DeleteCollectionProductModalSlice";
import {Translate} from "../../common/utils/Translate";
import {
    removeCollectionProductId,
    useLazyGetCollectionsQuery,
    useUpdateCollectionMutation
} from "../../pages/collectionsPage/CollectionsSlice";
import {useEffect, useState} from "react";
import {MoonLoader} from "react-spinners";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../app/rootReducer";
import {StringTranslation} from "../../common/utils/StringTranslation";

export const DeleteCollectionProductModalContainer = () => {

    const dispatch = useAppDispatch()

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {data: translations} = useGetTranslationsQuery()

    const {collections} = useAppSelector(state => state.collections);
    const {productId, collectionId} = useAppSelector(state => state.deleteCollectionProductModal)

    const [loading, setLoading] = useState<boolean>(false)
    const [updateCollectionTrigger] = useUpdateCollectionMutation();
    const [collectionTrigger] = useLazyGetCollectionsQuery();

    const productName = collections.find((item: { id: number; }) => item.id === collectionId)?.productIds.find((item: { id: any; }) => item.id === productId)?.name || ''

    const paramObject = {
        collectionId: collectionId,
        collectionName: collections.find((item: { id: number; }) => item.id === collectionId)?.name || '',
        productIds: collections.find((item: { id: number; }) => item.id === collectionId)?.productIds || [],
    }

    useEffect(() => {
        dispatch(removeCollectionProductId({collectionId: collectionId, productId: productId}))
    }, [collectionId, dispatch, productId])

    return (
        <div className="c-modal">
            <div className="c-modal__bg"/>
            <div className="c-modal__content">
                {!loading ? (
                    <>
                        <button className="c-modal__close" onClick={() => {
                            dispatch(setDeleteCollectionProductModal({
                                showPopup: false
                            }))
                        }}>
                            <img className="c-modal__icon" src="/images/cross.svg" alt="cross"/>
                        </button>

                        <header className="c-modal__header">
                            <h1>{StringTranslation('do-you-want-to-delete-this-item', translations, activeLanguage)}</h1>
                        </header>

                        <div className="c-modal__body">

                            {!!activeLanguage &&
                            <p>{StringTranslation('do-you-want-to-add-another-product-to-this-collection', translations, activeLanguage) + " " + Translate(productName, activeLanguage)}?</p>
                            }

                            <div className="c-modal__body__buttons">
                                <button className="c-btn c-btn--primary" onClick={() => {
                                    setLoading(true)
                                    updateCollectionTrigger(paramObject).then(() => {
                                        collectionTrigger().then(() => {
                                            setLoading(false)
                                            dispatch(setDeleteCollectionProductModal({
                                                showPopup: false
                                            }))
                                        })
                                    })
                                }}>
                                    <span>{StringTranslation('yes-im-sure', translations, activeLanguage)}</span>
                                </button>
                                <button className="c-btn c-btn--secondary" onClick={() => {
                                    dispatch(setDeleteCollectionProductModal({
                                        showPopup: false
                                    }))
                                }}>
                                    <span>{StringTranslation('cancel', translations, activeLanguage)}</span>
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={"c-loader"}>
                        <MoonLoader color={"#E52713FF"}/>
                    </div>
                )}
            </div>
        </div>
    )

}
