interface Props {
    text: string,
}

export const Tooltip = (props: Props) => {

    return (
        <div className='c-tooltip c-tooltip--product-range'>
            <img src="/images/info-filter.svg" alt="tooltip"/>
            <span className="tooltiptext">{props.text}</span>
        </div>
    )

}
