interface Props {
    color: string;
}

export const SvgPlay = (props: Props) => {

    return (
        <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 13.7 17.5" fill={props.color}>
            <polygon className="st0" points="0,0 0,17.5 13.7,8.8 "/>
        </svg>
    )
}