import {CategoryListInterface} from "../../interfaces/CategoryListInterface";
import {Translate} from "../../utils/Translate";
import {useState} from "react";
import {CategorySubItem} from "./CategorySubItem";
import {useAppSelector} from "../../../app/hooks";
import {ProductListItem} from "./ProductListItem";
import {ProductGridItem} from "./ProductGridItem";
import {useGetUserInfoQuery} from "../../../app/rootReducer";

interface Props {
    category: CategoryListInterface;
}

export const CategoryItem = (props: Props) => {
    const {category} = props;
    const {label, children, products} = category;

    const [active, setActive] = useState(false);

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}

    const {gridLayout, disableCheckbox} = useAppSelector(state => state.productRange);

    return (

        <div className={"c-products-list__item " + (active ? "is-active" : "")}>

            <div className="c-products-list__item__header" onClick={() => setActive(!active)}>
                {!!label && activeLanguage &&
                    <div className="c-products-list__item__header__title">
                        <span>{Translate(label, activeLanguage)}</span>
                    </div>
                }
                <div className="c-products-list__item__header__icon">
                    <span></span>
                    <span></span>
                </div>
            </div>

            {!!children && children.length > 0 ? (
                <div className="c-products-list__sub-items">
                    {children.map((child) => (
                        <CategorySubItem key={'category-' + child.id} child={child}
                                         disableCheckbox={disableCheckbox}/>
                    ))}
                </div>
            ) : (
                <div className="c-products-list__sub-items">
                    <div className="c-products-list__sub-item is-active">
                        <div
                            className={"c-products-list__sub-item__items " + (!!gridLayout ? 'c-products-list__sub-item__items--grid' : '')}>
                            {products.map((product) => {
                                if (!!gridLayout){
                                    return (
                                        <ProductGridItem key={'grid-' + product.id} product={product}
                                                     disableCheckbox={disableCheckbox}/>
                                    )
                                }
                                return (
                                    <ProductListItem key={'list-' + product.id} product={product}
                                                     disableCheckbox={disableCheckbox}/>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}




