import {LanguageInterface} from "../interfaces/LanguageInterface";

export const Translate = (texts: any, activeLanguage: LanguageInterface | null) => {

    const activeLanguageKey = activeLanguage?.localeCode

    if (activeLanguageKey && texts && texts[activeLanguageKey]) {
        return texts[activeLanguageKey]
    } else {
        return '';
    }

}
