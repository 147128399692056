import {ProductDetailsDataInterface} from "../../interfaces/ProductDetailsDataInterface";
import {LanguageInterface} from "../../interfaces/LanguageInterface";
import {StringTranslation} from "../../utils/StringTranslation";


interface Props {
    data: ProductDetailsDataInterface,
    translations: any,
    activeLanguage: LanguageInterface,
}

export const ProductGeneralInformation = (props: Props) => {

    const {data, activeLanguage, translations} = props;

    return (
        <div className="c-product-general-info">
            <h2>{StringTranslation('general-information', translations, activeLanguage)}</h2>
            <div dangerouslySetInnerHTML={{__html: data.description ?? ""}}/>
        </div>
    )

}
